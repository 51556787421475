import $ from 'jquery'

/* global System */
const loadSlick = () =>
  System.import(
    /* webpackChunkName: "slick" */
    'slick-carousel/slick/slick'
  )

$(document).ready(dhsv_vc_hero_slider())

function dhsv_vc_hero_slider() {
  if ($('.hero__slider').length > 0) {
    let dot = $('.dhsv_vc_slider .hero__inner .slick-dots li')
    loadSlick().then(() =>
      $('.dhsv_vc_slider .hero__slider').on('beforeChange', function (
        event,
        slick,
        currentSlide,
        nextSlide
      ) {
        dot.each(function (i) {
          if (i === nextSlide) {
            dot.removeClass('slick-active')
            $(this).addClass('slick-active')
          }
        })
      })
    )
    loadSlick().then(() =>
      $('.hero__slider').slick({
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        dots: true,
        autoplay: true,
      })
    )
    dot.find('button').on('click', function (e) {
      e.preventDefault()
      var slideno = $(this).data('slide')
      $('.hero__slider').slick('slickGoTo', slideno)
      dot.removeClass('slick-active')
      $(this).closest('li').addClass('slick-active')
    })
  }
}
