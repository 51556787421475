import $ from 'jquery'
import 'flickity/dist/flickity.pkgd.min'
import 'flickity-fade'

$(document).ready(() => {
  dhsv_vc_simple_slider()
})

function dhsv_vc_simple_slider() {
  $('.simple-slider').each((i, el) => {
    const options = {
      draggable: true,
      pageDots: false,
      prevNextButtons: true,
      cellAlign: 'center',
      wrapAround: true,
      fade: true,
      arrowShape: {
        x0: 10,
        x1: 60,
        y1: 50,
        x2: 65,
        y2: 45,
        x3: 20,
      },
      autoPlay: true,
    }
    $(el).flickity(options)
  })
}
