/**************************************************************
 * Scroll to ID (hash links)
 **************************************************************/

// Vars
var navbar = $('#anchors-menu')

if (navbar.length) {
  var navbarLink = $('#anchors-menu a'),
    navbarWrapper = $('.anchors-menu__wrapper'),
    navbarParent = navbar.parents('[data-vc-full-width-init="false"]'),
    alertBarH = $('.top-banner').outerHeight(),
    offsetTop = navbar.outerHeight() + alertBarH + 70,
    alertBarOffset = alertBarH ? alertBarH : 0
  var sticky = navbarParent[0].offsetTop - alertBarOffset
}

function scrollToID(oEvent) {
  // Animate to new location on-click
  if (window.pageYOffset > sticky + 200) {
    $('html, body').animate(
      {
        scrollTop: $($(oEvent.currentTarget).attr('href')).offset().top - 180,
      },
      800
    )
  } else {
    $('html, body').animate(
      {
        scrollTop: $($(oEvent.currentTarget).attr('href')).offset().top - 250,
      },
      800
    )
  }
  stickyAnchorsMenu()
  navbarLink.removeClass('is-active')
  $(this).addClass('is-active')

  return false
}

// Add the sticky class to the header when you reach its scroll position.

function stickyAnchorsMenu() {
  if (navbar.length) {
    let scrollPos = $(this).scrollTop()
    let scrollDistance = scrollPos + (offsetTop ? offsetTop : '0')
    if (window.pageYOffset > sticky + 200) {
      navbar.addClass('sticky')
    } else {
      navbar.removeClass('sticky')
    }

    $("#anchors-menu a[href^='#']").each(function () {
      let currLink = $(this),
        target = $(currLink.attr('href')),
        refElement = ''
      if (target.length) {
        if (target.is('section')) {
          refElement = target
        } else {
          refElement = target.parents('section')
        }
        if (
          refElement.position().top <= scrollDistance &&
          refElement.position().top + refElement.height() > scrollDistance
        ) {
          navbarLink.removeClass('is-active')
          currLink.addClass('is-active')
        } else {
          currLink.removeClass('is-active')
        }
      }
    })
    if ($(window).width() < 1024) {
      $('.anchors-menu__link.is-active').prependTo(navbarWrapper)
    }
  }
}

// On Scroll Event
$(window).scroll(function () {
  stickyAnchorsMenu()
})

// On Page Ready Event
$(document).ready(function () {
  stickyAnchorsMenu()
  if (navbar.length) {
    $(document).on('click', '#anchors-menu .js-expand', function (e) {
      e.preventDefault()
      navbarWrapper.toggleClass('is-opened')
    })
    navbarLink.click(scrollToID)
  }
})
