import cssVars from 'css-vars-ponyfill'
import 'jquery-match-height/dist/jquery.matchHeight-min'

const setBodyWidth = () => {
  cssVars({
    variables: {
      '--body-width': document.body.clientWidth + 'px',
      '--top-banner-height':
        document.querySelector('.top-banner').clientHeight - 5 + 'px',
    },
  })
}

const overlayedVideoEvents = () => {
  $('.js-wpb-video-overlay').on('click', function () {
    const $overlay = $(this)

    const $video = $overlay.closest('.wpb_video_wrapper').find('video')
    if ($video.length) $video[0].play()

    const $iframe = $overlay.closest('.wpb_video_wrapper').find('iframe')

    if ($iframe.length) {
      $iframe.attr('src', $iframe.attr('src') + '&rel=0&autoplay=1')
    }

    $overlay.fadeOut(300)
  })
}

const smoothScroll = (link, e) => {
  if (e) e.preventDefault()
  var hash = false
  if (!link && window.location.hash) {
    hash = window.location.hash
  } else if (link && link.indexOf('#') == 0) {
    hash = link
  } else if (
    link &&
    location.pathname.replace(/^\//, '') == link.pathname.replace(/^\//, '') &&
    location.hostname == link.hostname
  ) {
    hash = link.hash
  }

  if (hash && $(hash).length) {
    $('html, body').animate(
      {
        scrollTop: $(hash).offset().top + 70,
      },
      600
    )
    history.replaceState(null, null, hash)
  }
  $(window).trigger('hashchange')
}

const closeTopBanner = () => {
  if (localStorage.getItem('hide-topbanner') == 'true') {
    cssVars({
      variables: {
        '--top-banner-height': '0px',
      },
    })
    $('body').addClass('hide-topbanner')
  }

  $('.top-banner__close').on('click', function (e) {
    e.preventDefault()
    $('.top-banner').fadeOut()
    localStorage.setItem('hide-topbanner', true)
    $('body').addClass('hide-topbanner')
    $('.hero').css({ paddingTop: 0 })
    $('.top-banner + header').css({ marginTop: 0 })
    $('.top-banner ~ .wrap .anchors-menu').css({ top: 0 })
    $('.hero__mask, .header-mobile').css({ top: 0 })
  })
}

const openTopBanner = () => {
  if (localStorage.getItem('hide-topbanner') == 'false') {
    $('body').removeClass('hide-topbanner')
  }

  $('.info-flow-button').on('click', function (e) {
    e.preventDefault()
    $('.top-banner').fadeIn()
    localStorage.setItem('hide-topbanner', false)
    $('body').addClass('hide-topbanner')
    setBodyWidth()
    $('.hero').css({ paddingTop: '' })
    $('.top-banner + header').css({ marginTop: '' })
    $('.top-banner ~ .wrap .anchors-menu').css({ top: '' })
    $('.hero__mask, .header-mobile').css({ top: '' })
  })
}

export default {
  init() {
    // JavaScript to be fired on all pages
    setBodyWidth()
    overlayedVideoEvents()
    closeTopBanner()
    openTopBanner()
    $('body').on(
      'click',
      'a[href^="#"]:not([href="#"],[href="#0"],[href^="#fancy-"],[data-toggle],[role="tab"],[data-vc-accordion],[data-vc-tabs])',
      function (e) {
        smoothScroll($(this).attr('href'), e)
      }
    )

    $('body').on('click', '.searchform-toggle', function (e) {
      e.preventDefault()
      $('.contact-flow-search-popup').toggleClass('active-search')
    })

    $('body').on(
      'click',
      '.contact-flow-button.phone, .contact-flow-button.email, .contact-flow-button.contact',
      function (e) {
        e.preventDefault()
        $('.contact-flow-button:not(.contact):not(.close)').addClass('hide')
        $('.contact-flow-button.close').addClass('is-active')
        $('.contact-flow-buttons').addClass('is-active')
        $(this).next('.contact-flow-popup').slideDown()
      }
    )

    $('body').on(
      'click',
      '.contact-flow-button.close, .contact-flow-overlay',
      function (e) {
        e.preventDefault()
        $('.contact-flow-button').removeClass('hide')
        $('.contact-flow-buttons').removeClass('is-active')
        $('.contact-flow-button.close').removeClass('is-active')
        $('.contact-flow-popup').slideUp()
      }
    )

    $(window).scroll(function () {
      if ($(document).scrollTop() > 200) {
        $('body').addClass('scrolled')
      } else {
        $('body').removeClass('scrolled')
      }
    })

    if (window.location.hash) {
      smoothScroll(window.location.hash)
    }

    $(window).on('resize', function () {
      setBodyWidth()
    })
  },
  finalize() {
    // JavaScript to be fired on all pages, after page specific JS is fired
  },
}
