import $ from 'jquery'
// import bootstrap from 'bootstrap/js/src/tab'

$(document).ready(dhsv_vc_tabs())

function dhsv_vc_tabs() {
  // var triggerTabList = [].slice.call(document.querySelectorAll('#myTab a'))
  // triggerTabList.forEach(function (triggerEl) {
  //   var tabTrigger = new bootstrap.Tab(triggerEl)
  //
  //   triggerEl.addEventListener('click', function (event) {
  //     event.preventDefault()
  //     tabTrigger.show()
  //   })
  // })
}
