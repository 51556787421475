/* global System */
// register component(s) with react habitat

export default {
  SliderColumns: () =>
    System.import(
      /* webpackChunkName: "ImageSlider" */
      './SliderColumns'
    ),
}
