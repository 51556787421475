import $ from 'jquery'

$(document).ready(() => {
  dhsv_vc_course_archive()
  dhsv_vc_course_item()
  dhsv_vc_course_archive_select()
  dhsv_vc_course_archive_resize()
})

function groupBy(list = [], keyGetter = () => {}) {
  const map = new Map()
  list.forEach(item => {
    const key = keyGetter(item)
    const collection = map.get(key)
    if (!collection) {
      map.set(key, [item])
    } else {
      collection.push(item)
    }
  })
  return Array.from(map)
}

function dhsv_vc_course_archive_select() {
  let arr = []
  // itemArr = []

  let courseItems = $('.dhsv_vc_courses_archive .course-item')

  const noItemsText = $('.dhsv_vc_courses_archive .archive-no-items-text')

  $(this).toggleClass('active')

  $('.courses-archive-tags__item.active').each(function () {
    arr.push({
      slug: $(this).attr('data-slug'),
      parent: $(this).attr('data-parent'),
    })
  })

  let filtered = Array.from(new Set(arr.map(x => x.slug)))

  if (arr.length) {
    $('.courses-archive .course-item').addClass('hover-disable')
    $('.courses-archive .course-item').hide()
    $('.courses-archive').addClass('active')
  } else {
    $('.courses-archive .course-item').removeClass('hover-disable')
    $('.courses-archive .course-item').show()
    $('.courses-archive').removeClass('active')
  }

  const groupedFilters = groupBy(arr, item => item.parent)

  const usedFilters = groupedFilters
    .filter(([key]) => {
      return key !== undefined
    })
    .map(([key, value]) => {
      return [
        key,
        value
          .map(v => v.slug)
          .filter(
            slug => filtered.includes(slug) && !slug.includes('unsicher')
          ),
      ]
    })
    // eslint-disable-next-line no-unused-vars
    .filter(([key, value]) => {
      return value.length > 0
    })

  let itemsFiltered = []

  itemsFiltered = Array.from(courseItems).filter(element => {
    const slugs = element.getAttribute('data-tags')
    element.style.display = 'none'

    // eslint-disable-next-line no-unused-vars
    const itemFiltered = usedFilters.filter(([key, value]) => {
      const hasMatchValues = value.filter(slug => {
        return slugs.split(',').includes(slug)
      })

      return hasMatchValues.length > 0
    })

    return itemFiltered.length === usedFilters.length

    //return filtered.filter(x => slugs.split(',').includes(x)).length > 0
  })

  itemsFiltered.forEach(element => {
    element.style.display = 'block'
  })

  if (itemsFiltered <= 0) {
    noItemsText.css('display', 'block')
  } else {
    noItemsText.css('display', 'none')
  }

  // console.log({ groupedFilters }) //eslint-disable-line

  // $('.courses-archive .course-item').each(function (i) {
  //   let slugs = $(this).attr('data-tags'),
  //     arrSlugs = slugs.split(',')
  //
  //   arr.forEach(item => {
  //     if (arrSlugs.indexOf(item) != -1) {
  //       itemArr[i] = itemArr[i] !== undefined ? itemArr[i] + 1 : 1
  //     } else {
  //       itemArr[i] = itemArr[i] !== undefined ? itemArr[i] - 1 : -1
  //     }
  //   })
  // })

  // findGreatestIndices(itemArr).forEach(index => {
  //   $('.courses-archive .course-item').eq(index).removeClass('hover-disable')
  //   $('.courses-archive .course-item').eq(index).show()
  // })

  // availableHashtag()
}
function dhsv_vc_course_archive() {
  $('.courses-archive-tags__item').on('click', function () {
    $(this).toggleClass('active')
    dhsv_vc_course_archive_select()
  })

  // check url for hash
  var hash = window.location.hash.substring(1)
  if (hash.indexOf('course-') != -1) {
    var target = $(window.location.hash)
    if (target.length) {
      $('ul.vc_tta-tabs-list li').removeClass('vc_active')
      $('ul.vc_tta-tabs-list li').eq(1).focus().addClass('vc_active')
      $('.vc_tta-panels-container .vc_tta-panels .vc_tta-panel').removeClass(
        'vc_active'
      )
      $('.vc_tta-panels-container .vc_tta-panels .vc_tta-panel')
        .eq(1)
        .focus()
        .addClass('vc_active')
      setTimeout(function () {
        $('html, body').animate(
          {
            scrollTop: target.offset().top - 200,
          },
          3000,
          function () {
            // Callback after animation
            // Must change focus!
            var $target = $(target)
            $target.focus()
            if ($target.is(':focus')) {
              // Checking if the target was focused
              return false
            } else {
              $target.attr('tabindex', '-1') // Adding tabindex for elements not focusable
              $target.focus() // Set focus again
            }
            var modalid = target.attr('data-target')
            $(modalid).modal('toggle')
          }
        )
      }, 1500)
    }
  }
}

// function findGreatestIndices(arr) {
//   const val = Math.max(...arr)
//   const greatest = arr.reduce((indexes, element, index) => {
//     if (element === val) {
//       return indexes.concat([index])
//     } else {
//       return indexes
//     }
//   }, [])
//   return greatest
// }

// function availableHashtag() {
// let arr = [],
//   courseItems = document.querySelectorAll(
//     '.dhsv_vc_courses_archive .course-item:not(.hover-disable)'
//   ),
//   hashtags = document.querySelectorAll(
//     '.dhsv_vc_courses_archive .courses-archive-tags__item'
//   )
// courseItems.forEach(function (element) {
//   let slugs = element.getAttribute('data-tags')
//   arr.push(slugs.split(','))
// })
//
// let filtered = Array.from(new Set(Array.prototype.concat.apply([], arr)))
//
// hashtags.forEach(function (element) {
//   let slug = element.getAttribute('data-slug'),
//     arr = []
//   filtered.forEach(function (element) {
//     if (element == slug) {
//       arr.push(true)
//     } else {
//       arr.push(false)
//     }
//   })
//
//   if (arr.indexOf(true) > -1) {
//     element.style.opacity = '1'
//     element.style.pointerEvents = 'auto'
//   } else {
//     element.style.opacity = '0.3'
//     element.style.pointerEvents = 'none'
//   }
// })
// }

function dhsv_vc_course_item() {
  $(document)
    .on('mouseover', '.courses-archive .course-item', function () {
      $('.course-item').removeClass('hover-active')
      $('.course-item').not(this).addClass('hover-disable')
    })
    .on(
      'mouseleave',
      '.courses-archive:not(.active) .course-item',
      function () {
        $('.course-item').removeClass('hover-disable')
      }
    )
}

function dhsv_vc_course_archive_resize() {
  $(document).on('click', '.vc_tta-tab', function () {
    window.dispatchEvent(new Event('resize'))
  })
}
