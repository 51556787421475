import $ from 'jquery'
import cssVars from 'css-vars-ponyfill'

$(document).ready(() => {
  dhsv_vc_teaser_info()
  getLastTeaserWidth()
})

function dhsv_vc_teaser_info() {
  $('.teaser-info-js').on('click', function () {
    $('.teaser-info-js').removeClass('active')
    if (!$(this).hasClass('active')) {
      $(this).addClass('active')
      $(this).parents('.teaser-info').addClass('active')
      $(this).find('.teaser-info__description').slideDown()
    }
  })

  $(document).on('click', '.teaser-info-close-js', function () {
    $('.teaser-info-js').removeClass('active')
    $('.teaser-info').removeClass('active')
    $('.teaser-info__description').hide()
  })
}

function getLastTeaserWidth() {
  cssVars({
    variables: {
      '--last-teaser-info-width':
        document.querySelector('.teaser-info-js:last-child').offsetWidth + 'px',
    },
  })
}

$(window).on('resize', function () {
  getLastTeaserWidth()
})
