import { combineReducers } from 'redux'
import browser from './browser'
import router from './router'
import persons from './persons'
import courses from './courses'

const appReducer = combineReducers({
  browser,
  location: router,
  persons,
  courses,
})

export default appReducer
